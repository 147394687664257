import React from 'react';
import styled, { css } from 'styled-components';
import Option from '../components/Option';
import Layout from '../components/Layout';
import MenuSeijinshiki from '../components/menuSeijinshiki';
import QaSeijinshiki from '../components/QaSeijinshiki';
import SEO from '../components/SEO';
import FooterButton from '../components/FooterButton';
import VoiceBanner from '../components/VoiceBanner';

type BasePagePropsType = {
  className?: string;
};

const BasePage: React.FC<BasePagePropsType> = ({ className }) => {
  return (
    <div className={className}>
      <Layout>
        <SEO title={'成人式'} path={'seijinshiki'} />
        <MenuSeijinshiki />
        <VoiceBanner category={'seijinshiki'} />
        <Option category={'seijinshiki'} />
        <QaSeijinshiki />
        <FooterButton category={'seijinshiki'} />
      </Layout>
    </div>
  );
};

const Page = styled(BasePage)`
  ${() => {
    return css`
      & {
        .spacer30 {
          margin-bottom: 30px;
        }
      }
    `;
  }}
`;
export default Page;
