import React from 'react';
import styled, { css } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

type BaseMenuSeijinshikiPropsType = {
  className?: string;
};

const BaseMenuSeijinshiki: React.FC<BaseMenuSeijinshikiPropsType> = ({ className }) => (
  <div className={className}>
    <div className="row">
      <div className="col-12">
        <StaticImage src="../images/seijinshiki.jpg" alt="image" />
        <div className="spacer30"></div>

        <div className="card">
          <div className="card-body">
            <h5 className="caption-title">成人式応援企画</h5>
            <ul>
              <li>和装１着の料金で洋装も着れるプランです</li>
            </ul>
            <div className="spacer30"></div>
            <div className="kanban">
              <p className="title">振袖・ドレス</p>
              <p className="sub-title">フルパックプラン</p>
              <p className="limited-price">
                <small>料金</small> 66,000円 <small>(税込)</small>
              </p>
              <p className="price">通常料金 110,000円</p>
              <p className="description">44,000円もお得!</p>
            </div>
            <div className="spacer30"></div>
            <ul>
              <li className="title">【含まれる内容】</li>
              <li>振袖・ドレス・衣装に関わる小物一式</li>
              <li>着付・ヘアセット・撮影料・台紙アルバム２ポーズ１冊</li>
              <li>（メイクは別途料金）</li>
            </ul>
            <div className="spacer30"></div>
            <ul>
              <li className="title">【当日ご用意いただくもの】</li>
              <li>足袋・肌襦袢</li>
              <li>ご用意がない場合はレンタルとなります</li>
            </ul>
            <div className="spacer30"></div>
            <div className="kanban">
              <p className="title">羽織袴・タキシード</p>
              <p className="sub-title">フルパックプラン</p>
              <p className="limited-price">
                <small>料金</small> 50,600円 <small>(税込)</small>
              </p>
              <p className="price">通常料金 81,400円</p>
              <p className="description">30,800円もお得!</p>
            </div>
            <div className="spacer30"></div>
            <ul>
              <li className="title">【含まれる内容】</li>
              <li>羽織袴・タキシード・衣装に関わる小物一式</li>
              <li>着付・ヘアセット・撮影料・台紙アルバム２ポーズ１冊</li>
            </ul>
            <div className="spacer30"></div>
            <ul>
              <li className="title">【当日ご用意いただくもの】</li>
              <li>足袋・U首の肌着</li>
              <li>ご用意がない場合はレンタルとなります</li>
            </ul>
            <div className="spacer30"></div>
          </div>
        </div>

        <div className="spacer30"></div>
        <h5 className="caption-title">※以下は通常の料金表となります</h5>

        <div className="spacer30"></div>

        <h4 className="caption-title">女性&nbsp;料金表</h4>

        <div className="spacer30"></div>

        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>内容</th>
              <th>料金(税込)</th>
            </tr>
            <tr>
              <td>撮影料</td>
              <td>8,800円</td>
            </tr>
            <tr>
              <td>振袖&nbsp;レンタル</td>
              <td>33,000円</td>
            </tr>
            <tr>
              <td>着付料</td>
              <td>8,800円</td>
            </tr>
            <tr>
              <td>ヘアセット</td>
              <td>4,400円</td>
            </tr>
            <tr>
              <td>メイク</td>
              <td>3,300円</td>
            </tr>
            <tr>
              <td>衣装を着ておでかけ</td>
              <td>11,000円</td>
            </tr>
            <tr>
              <td>ドレスを着て撮影</td>
              <td>33,000円</td>
            </tr>
          </tbody>
        </table>

        <div className="spacer30"></div>

        <h4 className="caption-title">男性&nbsp;料金表</h4>

        <div className="spacer30"></div>

        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>内容</th>
              <th>料金(税込)</th>
            </tr>
            <tr>
              <td>撮影料</td>
              <td>8,800円</td>
            </tr>
            <tr>
              <td>羽織袴&nbsp;レンタル</td>
              <td>22,000円</td>
            </tr>
            <tr>
              <td>着付料</td>
              <td>6,600円</td>
            </tr>
            <tr>
              <td>ヘアセット</td>
              <td>2,200円</td>
            </tr>
            <tr>
              <td>衣装を着ておでかけ</td>
              <td>11,000円</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

const MenuSeijinshiki = styled(BaseMenuSeijinshiki)`
  ${() => {
    return css`
      & {
        .spacer10 {
          margin-bottom: 10px;
        }

        .spacer20 {
          margin-bottom: 20px;
        }

        .spacer30 {
          margin-bottom: 30px;
        }

        .spacer50 {
          margin-bottom: 50px;
        }

        .spacer100 {
          margin-bottom: 100px;
        }

        .flex-wrapper {
          display: flex;
          flex-direction: column;
        }

        .flex-wrapper-row {
          display: flex;
          flex-direction: row;
        }

        .flex-item {
          padding: 0;
          line-height: 2.5;
        }

        .w33 {
          width: 33%;
        }

        .w100 {
          width: 100%;
        }

        .pw10 {
          padding: 0 10px 0;
        }

        .flex-spacer {
          flex: 1;
          padding: 0 10px;
        }

        .plan-wrap {
          margin: 0 0 10px;
          padding: 10px;
          border: solid 1px #eee;
          border-radius: 10px;
        }

        .plan-title {
          padding: 10px 0;
          margin: 10px 0;
          font-size: 1.4rem;
          font-weight: 200;
          line-height: 1.2rem;
        }

        // .plan-title {
        // font-size: 1.5rem;
        // font-weight: 700;
        // margin-bottom: 0.5rem;
        // }
        .plan-subtitle {
          font-weight: 700;
          margin-bottom: 0.5rem;
        }

        .price-memo {
          font-weight: 700;
        }

        .plan-price {
          font-size: 1.4rem;
          font-weight: 700;
          color: #dc3545;
          letter-spacing: -0.05rem;
        }

        .option-title {
          font-size: 1.5rem;
          font-weight: 500;
        }

        .form-title {
          font-size: 1.2rem;
          font-weight: 500;
          margin-bottom: 1rem;
        }

        table thead th {
          text-align: center;
          width: 50%;
        }

        table tbody tr th,
        td {
          text-align: center;
          width: 50%;
        }

        ul,
        li {
          margin: 0;
        }
        li.title {
          list-style-type: none;
          margin-left: -26px;
        }
        .caption-title {
          font-weight: 700;
          text-align: center;
          margin-bottom: 30px;
        }

        .carousel-wrapper {
          margin: 30px;
        }
        .total-price-wrapper {
          margin-top: 30px;
          background: #f3b54a;
          padding: 10px 30px;
          border-radius: 6px;
        }
        .total-price {
          font-weight: 700;
          font-size: 1.5rem;
          color: white;
        }
        .kanban {
          display: flex;
          flex-direction: column;
          border: solid 3px #ff497d;
          margin: 20px;
          padding: 30px;
          border-radius: 6px;
          p.title {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
          }
          p.sub-title {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
          }
          p.limited-price {
            font-size: 30px;
            font-weight: 700;
            text-align: center;
            margin: 24px;
            color: #ff497d;
          }
          p.price {
            font-size: 20px;
            text-align: center;
            margin: 10px;
          }
          p.description {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
          }
        }
        table thead th {
          text-align: center;
          width: 50%;
        }
        table tbody tr th,
        td {
          text-align: center;
          width: 50%;
        }
        ul,
        li {
          margin: 0;
        }
        .caption-title {
          font-weight: 700;
          text-align: center;
          margin-bottom: 30px;
        }
        .carousel-wrapper {
          margin: 30px;
        }
        // リボン見出し
        h2 {
          position: relative;
          margin: 30px;
          text-align: center;
        }

        h2:before,
        h2:after {
          position: absolute;
          z-index: 0;
          bottom: -10px;
          display: block;
          content: '';
          border: 1em solid rgb(190, 36, 88);
        }

        h2:before {
          left: -30px;
          border-left-width: 15px;
          border-left-color: transparent;
        }

        h2:after {
          right: -30px;
          border-right-width: 15px;
          border-right-color: transparent;
        }

        h2 span {
          position: relative;
          z-index: 1;
          display: block;
          padding: 1rem 2rem;
          color: #fff;
          background: rgb(245, 90, 141);
        }

        h2 span:before,
        h2 span:after {
          position: absolute;
          bottom: -10px;
          display: block;
          width: 10px;
          height: 10px;
          content: '';
          border-style: solid;
          border-color: #b70505 transparent transparent transparent;
        }

        h2 span:before {
          left: 0;
          border-width: 10px 0 0 10px;
        }

        h2 span:after {
          right: 0;
          border-width: 10px 10px 0 0;
        }
      }
    `;
  }}
`;

export default React.memo(MenuSeijinshiki);
